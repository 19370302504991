import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactGA from 'react-ga';

import * as API from '../apis';
import breakpoints from '../styles/breakpoints';
import { ENTER } from '../constants/keys';
import { ACTION, CATEGORY } from '../constants/ga';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Input from '../components/Input';
import Button from '../components/Button';
import Message from '../components/Message';

const MainContent = styled.div`
  min-height: 100vh;
  background: rgb(245, 247, 250);
`;

const Card = styled.div`
  max-width: 570px;
  margin: 0 auto;
  padding: 5rem 1rem;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 1rem;
  }
`;

const Header = styled.header`
  padding: 2.5rem;
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const Body = styled.section`
  padding: 1.5rem 4.375rem 4.375rem;
  text-align: left;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 2rem 2rem;
  }
`;

const Field = styled.div`
  margin-bottom: 1.5rem;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  overflow: hidden;
`;

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const invalid = !email || !captcha;

  const navigateToLogin = () => {
    setTimeout(() => navigate('/login/'), 15000);
  };

  const handleSubmit = async () => {
    if (invalid || loading) {
      return;
    }

    try {
      setLoading(true);
      setErrorMessage('');
      await API.forgotPassword({ email, captcha });
      setLoading(false);
      setSuccessMessage(
        'Yêu cầu của bạn đã được gửi thành công. Vui lòng kiểm tra email và làm theo hướng dẫn. Bạn sẽ được tự động chuyển tới trang đăng nhập sau 15s.'
      );
      setEmail('');
      navigateToLogin();
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Có lỗi xảy ra. Vui lòng thử lại sau.');
      }
      console.error(error);
    }

    ReactGA.event({
      category: CATEGORY.USER_MANAGEMENT,
      action: ACTION.FORGOT_PASSWORD,
    });
  };

  return (
    <Layout mainContent={MainContent}>
      <SEO title="Quên mật khẩu" />
      <Card>
        <Header>Quên mật khẩu</Header>
        <Body>
          <Field>
            <Input
              label="Email"
              placeholder="Nhập email của tài khoản"
              value={email}
              onChange={event => setEmail(event.target.value)}
              onKeyDown={event => {
                if (event.keyCode === ENTER) {
                  handleSubmit();
                }
              }}
            />
          </Field>
          <SubmitButton loading={loading} disabled={invalid || loading} onClick={handleSubmit}>
            Lấy lại mật khẩu
          </SubmitButton>
          {errorMessage && <Message error>{`* ${errorMessage}`}</Message>}
          {successMessage && <Message>{successMessage}</Message>}
          <CaptchaWrapper>
            <ReCAPTCHA sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY} onChange={setCaptcha} />
          </CaptchaWrapper>
        </Body>
      </Card>
    </Layout>
  );
}

export default ForgotPasswordPage;
